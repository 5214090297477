import React from "react";

const SectionHeader = ({ title = "", description = "" }) => {
  return (
    <div className="section-header-container">
      <h2 className="section-header-description">{description}</h2>
      <h1 className=" title section-header-title">{title}</h1>
    </div>
  );
};

export default SectionHeader;
