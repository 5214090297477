import React from "react";
import SectionHeader from "../SectionHeader";
import ContainerLayout from "../../layouts/Container";
import RowLayout from "../../layouts/Row";
import ColLayout from "../../layouts/Col";
import SectionLayout from "../../layouts/Section";

import { PrimaryButton, SecondaryButton } from "../../layouts/Button";
import { Bio } from "../../data/constants";

import ProfileImage from "./../../assets/Images/programmer.jpg";

const Blogs = () => {
  return (
    <SectionLayout className="" id="blogs">
      <div id="blogs" className="blog-main-container">
        <ContainerLayout>
          <RowLayout>
            <ColLayout>
              <SectionHeader
                title="Blog"
                description="Some of my personal article."
              />
            </ColLayout>
          </RowLayout>
          <RowLayout>
            {Bio.blogs &&
              Bio.blogs.length > 0 &&
              Bio.blogs.map((blog) => {
                return (
                  <ColLayout
                    key={blog.id}
                    className="col-md-4 col-sm-6 col-xs-12"
                  >
                    <div className="blog-section-main-container">
                      <ColLayout>
                        <img
                          src={ProfileImage}
                          alt="kawal jain"
                          style={{
                            //   width: "100px",
                            width: "100%",
                            height: "200px",
                            backgroundColor: "#FFFFFF",
                            borderRadius: "10px",
                            boxShadow: "0 0 16px 2px rgba(0, 0, 0, 0.3)",
                          }}
                        />
                      </ColLayout>
                      <ColLayout>
                        {blog.tags && blog.tags.length > 0 && (
                          <div className="blog-tags">
                            {blog.tags.map((tag) => {
                              return (
                                <div className="blog-tag-content" key={tag}>
                                  {tag}
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </ColLayout>

                      <ColLayout>
                        <div className="blog-title">{blog.title}</div>
                      </ColLayout>

                      <ColLayout>
                        <div className="blog-description">
                          {blog.description}
                        </div>
                      </ColLayout>
                      <ColLayout>
                        <SecondaryButton
                          onClick={() => {
                            window.open(blog.link, "_blank");
                          }}
                          title={"Read More"}
                        />
                      </ColLayout>
                    </div>
                  </ColLayout>
                );
              })}
          </RowLayout>

          <RowLayout>
            <ColLayout>
              <div className=" project-more-container text-center">
                <PrimaryButton
                  onClick={() => {
                    window.open(Bio.medium, "_blank");
                  }}
                  title={"View More"}
                />
              </div>
            </ColLayout>
          </RowLayout>
        </ContainerLayout>
      </div>
    </SectionLayout>
  );
};

export default Blogs;
