import React from "react";
import { motion } from "framer-motion";

import SectionHeader from "../SectionHeader";
import ContainerLayout from "../../layouts/Container";
import RowLayout from "../../layouts/Row";
import ColLayout from "../../layouts/Col";

import { PrimaryButton } from "../../layouts/Button";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectCoverflow, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/pagination";
import ListLayout from "../../layouts/ListLayout";

const Projects = ({ projects = [], showViewMore = false, isPage = false }) => {
  const navigate = useNavigate();

  return (
    <div id="projects" className="project-main-container">
      <ContainerLayout>
        <RowLayout>
          <ColLayout>
            <SectionHeader title="Projects" description="My Work" />
          </ColLayout>
        </RowLayout>

        <RowLayout>
          <Swiper
            effect={"coverflow"}
            grabCursor={true}
            className="project-detail-slider"
            modules={[EffectCoverflow, Autoplay, Pagination]}
            loop={true}
            slidesPerView={1}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 50,
              },
            }}
            autoplay={{
              delay: 4000,
              disableOnInteraction: true,
              pauseOnMouseEnter: true,
            }}
          >
            {projects.length > 0 &&
              projects.map((project) => {
                return (
                  <SwiperSlide
                    className="project-detail-main-container project-detail-slider"
                    key={project.id}
                  >
                    <ColLayout key={project.id}>
                      <ListLayout
                        title={project.title}
                        img={project.img}
                        skillSet={project.skillSet}
                        {...project}
                      />
                    </ColLayout>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </RowLayout>
        {showViewMore ? (
          <RowLayout>
            <ColLayout className="view-main-container">
              <motion.div
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 1, scale: 1 }}
              >
                <div className=" project-more-container text-center">
                  <PrimaryButton
                    title={"View More"}
                    onClick={() => {
                      navigate("/projects");
                    }}
                  />
                </div>
              </motion.div>
            </ColLayout>
          </RowLayout>
        ) : (
          <></>
        )}
      </ContainerLayout>
    </div>
  );
};

export default Projects;
