import { ProjectWork } from "./../data/constants";
import React from "react";
import { motion } from "framer-motion";

// import SectionHeader from "../SectionHeader";
// import ContainerLayout from "../../layouts/Container";
// import RowLayout from "../../layouts/Row";
// import ColLayout from "../../layouts/Col";
// import ContentLayout from "../../layouts/ContentLayout";
// import { Fade } from "react-reveal";

// import { darkTheme } from "../../utils/Themes";
// import { ButtonDefault, PrimaryButton } from "../../layouts/Button";
// import { useNavigate } from "react-router-dom";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Autoplay, EffectCoverflow, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/pagination";
import ContainerLayout from "../layouts/Container";
import RowLayout from "../layouts/Row";
import ColLayout from "../layouts/Col";
import SectionHeader from "../components/SectionHeader";
import { ButtonDefault, SecondaryButton } from "../layouts/Button";
import ListLayout from "../layouts/ListLayout";
import { darkTheme } from "../utils/Themes";
import ContentLayout from "../layouts/ContentLayout";
import { Fade } from "react-reveal";

const ProjectDescriptionLayout = ({ project, isPage }) => {
  return (
    <div className="project-detail-descrption-container">
      <div right duration={1000}>
        <h1
          className="project-detail-descrption-heading"
          style={{ color: darkTheme.text }}
        >
          {project.title}
        </h1>
      </div>

      <div right duration={1000}>
        {isPage ? (
          project.description.map((sentence, index) => {
            return <ContentLayout key={index} content={sentence} />;
          })
        ) : (
          <ContentLayout content={project.d} />
        )}

        {/* {project.description.map((sentence, index) => {
          return <ContentLayout key={index} content={sentence} />;
        })} */}
      </div>

      {project.isOpenSource && project.live ? (
        <div
          right
          duration={1000}
          className="project-detail-descrption-sub-container gap-2"
        >
          <Fade right duration={1000}>
            <ButtonDefault
              title={"Live Project"}
              onClick={() => {
                window.open("https://markdown.kawaljain.com/", "_blank");
              }}
            />
          </Fade>

          {project.isOpenSource && project.github && (
            <Fade right duration={1000}>
              <ButtonDefault
                title={"Github Repo"}
                onClick={() => {
                  window.open(
                    "https://github.com/kawaljain/markdown-parser",
                    "_blank"
                  );
                }}
              />{" "}
            </Fade>
          )}
        </div>
      ) : null}
    </div>
  );
};

const ProjectImgLayout = ({ project, isPage }) => {
  return (
    <div left duration={2000}>
      <div className="project-detail-img-container">
        {project.isStatic ? (
          <img src={project.img} alt={project.title} />
        ) : (
          <project.Svg theme={darkTheme} {...project.svgConfig} />
        )}
      </div>
    </div>
  );
};
function ProjectPage() {
  return (
    <div className="mgBottom20 project-page">
      <div
        className="project-main-container project-page"
        style={{
          boxShadow: "rgba(23, 92, 230, 0.15) 0px 4px 24px",
        }}
      >
        <ContainerLayout>
          <RowLayout>
            <ColLayout>
              <SectionHeader title="Projects" description="My Work" />
            </ColLayout>
          </RowLayout>

          <RowLayout>
            {ProjectWork.length > 0 &&
              ProjectWork.map((project) => {
                return (
                  <ColLayout
                    className="col-md-4 col-sm-2 col-xs-10 project-card "
                    key={project.id}
                  >
                    <ListLayout
                      title={project.title}
                      img={project.img}
                      skillSet={project.skillSet}
                      {...project}
                    />
                  </ColLayout>
                );
              })}
          </RowLayout>

          {/* <RowLayout>
            <div
              className="project-detail-main-container project-page-container "
              key={project.id}
            >
              <>
                <ColLayout>
                  <ProjectImgLayout project={project} isPage={isPage} />
                </ColLayout>
                <ColLayout>
                  <ProjectDescriptionLayout project={project} isPage={isPage} />
                </ColLayout>
              </>
            </div>
          </RowLayout> */}
        </ContainerLayout>
      </div>
    </div>
  );
}

export default ProjectPage;
