import React from "react";
import { Tilt } from "react-tilt";

const ServiceCard = ({ title, description, images, Icon }) => {
  return (
    <Tilt>
      <div className="service-card">
        <h3 class=" service-card-title ">{title}</h3>
        <hr className="divider" />
        <div class="item-desc">{description}</div>
        <div class="item-icon gap-2">
          {images.length > 0 &&
            images.map((img) => {
              return (
                <img
                  alt={title}
                  title={title}
                  class="tech-icon rounded me-2 mt-2"
                  src={`${img}`}
                />
              );
            })}
        </div>
        {/* <div className="service-card ">
        <div className="text-center">
          <Icon color="white" size={100} />
          <div className="service-card-title mgTop10 ">{title}</div>
        </div>
      </div> */}
      </div>
    </Tilt>
  );
};

export default ServiceCard;
