import React from "react";
import SectionHeader from "../SectionHeader";
import { GiThunderBlade } from "react-icons/gi";
import ContainerLayout from "../../layouts/Container";
import RowLayout from "../../layouts/Row";
import ColLayout from "../../layouts/Col";
import ProfileImage from "./../../assets/Images/programmer.jpg";

import { Bio } from "../../data/constants";
import SectionLayout from "../../layouts/Section";
import ServiceCard from "../cards/ServiceCard";
import ContentLayout from "../../layouts/ContentLayout";

const About = () => {
  return (
    <SectionLayout id="about">
      <ContainerLayout>
        <RowLayout className="mgTop20 ">
          <ColLayout>
            <SectionHeader title="Overview" description="Introduction" />
          </ColLayout>
        </RowLayout>
        <RowLayout className="mgTop20  ">
          <ColLayout className="col-md-6 col-xs-12">
            <ContentLayout content={Bio.description}></ContentLayout>
          </ColLayout>
          <ColLayout className="col-md-6 col-sm-12  d-none d-sm-block ">
            <img
              src={ProfileImage}
              alt="kawal jain"
              className="img-responsive rounded img-thumbnail"
            />
          </ColLayout>
        </RowLayout>

        <RowLayout className="mgTop40 overview-section ">
          <div className="section-header-container">
            <h2 className=" title section-header-title">{"What I do"}</h2>
          </div>
          {Bio.services.map((service) => {
            return (
              <ColLayout
                key={service.id}
                className="col-lg-3 col-md-6 col-sm-6 col-xs-12 item "
              >
                <ServiceCard
                  title={service.title}
                  description={service.description}
                  images={service.images}
                  Icon={GiThunderBlade}
                  {...service}
                />
              </ColLayout>
            );
          })}
        </RowLayout>
      </ContainerLayout>
    </SectionLayout>
  );
};

export default About;
