import About from "../components/sections/About";
import Contact from "../components/sections/Contact";
import Experience from "../components/sections/Experience";
import Hero from "../components/sections/Hero";
import Projects from "../components/sections/Projects";
import Roles from "../components/sections/Roles";
import Skills from "../components/sections/Skills";
import bannerBg from "../assets/Images/bannerbg.webp";
import SectionLayout from "../layouts/Section";

import { ProjectWork } from "../data/constants";

function Home() {
  const allProject = ProjectWork.filter((project) => project.showMainPage);
  return (
    <>
      <Hero />
      <SectionLayout className="section-wrapper">
        <Roles />
      </SectionLayout>
      <Skills />

      <SectionLayout className="section-wrapper">
        <About />
      </SectionLayout>

      <Experience />

      <SectionLayout
        className="project-section mb-5 "
        style={{
          backgroundImage: `url(${bannerBg})`,
        }}
      >
        <Projects projects={allProject} showViewMore={false} />
      </SectionLayout>
      <Contact />
    </>
  );
}

export default Home;
