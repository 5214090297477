import React from "react";

import ContainerLayout from "../../layouts/Container";
import RowLayout from "../../layouts/Row";
import ColLayout from "../../layouts/Col";

const Footer = () => {
  return (
    <div className="footer pdBottom10">
      <ContainerLayout>
        <RowLayout>
          <ColLayout>
            <div>
              <div className="footer-container ">
                <p className="">
                  © {`${new Date().getFullYear()} Developed & Managed with`}
                  <span className="span-heart">❤️</span> by Kawal Jain. All
                  rights reserved.
                </p>
              </div>
            </div>
          </ColLayout>
        </RowLayout>
      </ContainerLayout>
    </div>
  );
};

export default Footer;
