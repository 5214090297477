import React from "react";
import ColLayout from "./Col";

import { ButtonDefault } from "./Button";

const ListLayout = ({ img, title, skillSet, ...props }) => {
  return (
    <div className={`project-slider-container  project-card-view `}>
      <ColLayout className="image-container">
        <img src={img} alt="kawal jain" className="img-fluid" />
      </ColLayout>
      <ColLayout className="">
        <ColLayout>
          <div className="project-slider-title">{title}</div>
        </ColLayout>
        <ColLayout>
          <div className="ex-tag-container px-3">
            {skillSet?.map((skill, index) => (
              <div className="ex-tag" key={index}>
                {skill}
              </div>
            ))}
          </div>
        </ColLayout>
        <ColLayout>
          <div className="project-slider-description px-3">{props.d}</div>
        </ColLayout>
        <ColLayout>
          {false && (
            <div className="project-slider-end-container px-3 gap-2 ">
              {props.github && (
                <ButtonDefault
                  className={"gap-2 list-layout-button"}
                  onClick={() => {
                    window.open(props.github, "_blank");
                  }}
                >
                  <svg
                    stroke="currentColor"
                    fill="none"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="w-max h-10"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path>
                  </svg>
                  <span className="ms-2">Github</span>
                </ButtonDefault>
              )}
              <ButtonDefault
                title={"Read More"}
                className={"gap-2 list-layout-button"}
              />
            </div>
          )}
        </ColLayout>
      </ColLayout>
    </div>
  );
};

export default ListLayout;
