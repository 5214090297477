import React from "react";

export const ButtonDefault = ({ title, children, className = "", onClick }) => {
  return (
    <div
      className={`btn  btn-outline-primary  cursor-pointer ${className} `}
      onClick={onClick}
    >
      {children && children}
      {title && title}
    </div>
  );
};
export const PrimaryButton = ({
  children,
  href = "#",
  className = "",
  onClick,
  title,
  ...rest
}) => {
  return (
    <div
      className={`button-primary cursor-pointer ${className}`}
      onClick={onClick}
      {...rest}
    >
      {children && <> {children}</>}
      {title && <>{title} </>}
    </div>
  );
};

export const SecondaryButton = ({
  children,
  title,
  href = "#",
  onClick,
  className = "",
  ...rest
}) => {
  return (
    <div
      className="button-secondary-button cursor-pointer "
      onClick={onClick}
      href={href}
      {...rest}
    >
      {children && <> {children}</>}
      {title && <>{title} </>}
    </div>
  );
};
